/* JavaScript used throughout the site, except for the Dashboard pages. jQuery is already included. */
import 'scss/app.scss'
import 'select2'
import 'bootstrap'

// Refresh dashboards when a different HAN member is selected
// This happens outside of Vue because we need a hard refresh
$(document).ready(() => {
  // For the dashboard pages
  $('#optionsModal select').on('change', function() {
    $(this)
      .closest('form')
      .submit()
  })
  $('#optionsModal input[type="checkbox"]').on('change', function() {
    $(this)
      .closest('form')
      .submit()
  })

  // Hack to run this only on the Instructions page
  if ($('#instructions').length) {
    $('.jumbotron').scrollspy({
      target: '#scrollspy',
      offset: 50,
    })
  }

  // Value list/member pages
  // Initialize select2 components
  $('#filterCollapse')
    .find('.select2-enable select')
    .select2()
  // Trigger form submit when ANY input is changed
  $('#filterCollapse').on('change', 'input,select', function() {
    $('.value-table').addClass('filtering')
    $(this)
      .closest('form')
      .submit()
  })
})
